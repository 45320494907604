import * as React from "react"
import { Link } from "gatsby"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/us-public/",
    label: "US PUBLIC SECTOR",
  },
]

const resourcesList = [
  {
    title: "Penetration Testing",
    subtitle: "",
    LinkUr: "https://sp1.sdcdn.app/pdf/Pen-Test-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMVDR",
    subtitle: " Managed Vulnerability Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MVDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="US PUBLIC SECTOR" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-2">
      <section className="section-title-top--platform">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>US PUBLIC SECTOR</h1>
              <h4>DEFENDING OUR COMMUNITIES TOGETHER</h4>
              <p className="content">We Detect So You Can Protect</p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-copy-img-r about-us--hero-img-us">
      <div className="container">
        <div className="row">
          <div className="section-col-1 desk-only">
            <div className="side-binder about-us--gives">
              <h3>
                STOP <br /> BREACHES
              </h3>
              <h6>
                SERVICE CONTINUITY. <br />
                PROTECT THE VULNERABLE.
              </h6>
            </div>
          </div>
          <div className="section-col-2">
            <div className="side-binder about-us--gives mob-only">
              <h1>OUR MISSION</h1>
              <h3>TO BE VIGILANT</h3>
            </div>
            <p>
              In 2019, the city of Baltimore’s networks were taken hostage in a
              ransomware attack. All servers were taken offline, some for weeks.
              The financial impact was incalculable in security and productivity
              lost, not to mention the destruction of public trust.
            </p>
            <p>
              The US Public Sector is a favorite target by threat actors for two
              reasons: One, attacking your industry causes maximum disruption
              and has the potential to halt society. Two, because your industry
              is relied on so heavily as critical infrastructure threat actors
              are willing to bet that you will do whatever they want (e.g. pay
              millions of dollars in ransom) so that you can be up and running
              again quickly. Therefore, if you function in the realms of
              federal, state, local, educational, utilities, public service or
              emergency response, you are a highly attractive target for attack.
            </p>
            <p>
              Cybersecurity never seems urgent – until it is. If you’re like
              most, you are probably being pulled in a variety of different
              directions each day – all the more reason you need a partner who
              will ensure that an urgent cyber security moment never happens.
            </p>

            <p>
              From our 24/7/365 US based CyberDNA® Control and Command Center,
              we watch your network, endpoints, email flow, customer
              integration, decentralized network, in addition to global threat
              trends and public sector specific tactics. We inspect all your
              cyber data, and we constantly monitor for malicious activity.
            </p>
            <p>
              Bonus: we have the highest true-positive analysis process, so your
              busy staff is only interrupted with actionable intelligence.
              Result: you can stay focused on what matters most.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div className="grid--bg --for-it-ops-1--center">
      <section className="section-client-quote">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h3>
                  “I love having Vigilant on our side because we need people who
                  eat, breathe and sleep security and your team does. Your
                  detection rate is amazing. You find threats the same day they
                  occur. When Vigilant calls, I know to pick up.”
                </h3>
                <hr />
                <h6>- CLIENT IN US PUBLIC SECTOR</h6>
                <p className="pBlue">
                  (For security reasons, Vigilant never reveals the identity of
                  our clients)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
